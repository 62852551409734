import React, { useState, useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import { motion } from 'framer-motion';
import './address.css'; // Import custom CSS for styling
import { FaTimes, FaEdit } from 'react-icons/fa'; // Import icons
import { auth, db } from '../../firebase/Firebase'; // Firebase setup
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Firestore methods
import Select from 'react-select'; // Import react-select
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

const AddressBook = () => {
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // To track if it's editing or adding a new address
  const [editIndex, setEditIndex] = useState(null); // To track which address is being edited
  const [userData, setUserData] = useState({
    userName: '',
    userId: '',
    shippingAddresses: [], // Array to hold multiple addresses
  });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // State for the delete confirmation dialog
  const [addressToDelete, setAddressToDelete] = useState(null); // Store the index of the address to delete
  const [loading, setLoading] = useState(true); // State to track loading status

  const handleDeleteClick = (index) => {
    setAddressToDelete(index); // Store the index of the address to be deleted
    setShowDeleteConfirm(true); // Show the confirmation box
  };

  const confirmDelete = async () => {
    const user = auth.currentUser;
    if (user && addressToDelete !== null) {
      const userDocRef = doc(db, 'users', user.uid);
      const updatedAddresses = [...userData.shippingAddresses];
      updatedAddresses.splice(addressToDelete, 1); // Remove the selected address
      await updateDoc(userDocRef, { shippingAddresses: updatedAddresses });
      setUserData((prevData) => ({
        ...prevData,
        shippingAddresses: updatedAddresses,
      }));
    }
    setShowDeleteConfirm(false); // Close the confirmation box
    setAddressToDelete(null); // Reset the address to delete
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false); // Close the confirmation box without deleting
    setAddressToDelete(null);
  };

  const [formData, setFormData] = useState({
    receiverName: '',
    contactNumber: '',
    country: '',
    countryFlagCode: '',
    address: '',
    postCode: '',
  });

  const countryOptions = [
    { value: 'Maldives', code: 'mv', label: <><img src="https://flagcdn.com/w20/mv.png" alt="Maldives" /> Maldives</> },
    
  ];

  // Open modal in Add Mode
  const handleAddNewAddress = () => {
    setIsEditMode(false); // Setting to Add mode
    setFormData({
      receiverName: '',
      contactNumber: '',
      country: '',
      countryFlagCode: '',
      address: '',
      postCode: '',
    });
    setShowModal(true);
  };

  // Open modal in Edit Mode
  const handleEditAddress = (index) => {
    setIsEditMode(true); // Setting to Edit mode
    setEditIndex(index); // Storing which address is being edited
    const addressToEdit = userData.shippingAddresses[index];
    setFormData({
      receiverName: addressToEdit.receiverName,
      contactNumber: addressToEdit.contactNumber,
      country: addressToEdit.country,
      countryFlagCode: addressToEdit.countryFlagCode,
      address: addressToEdit.address,
      postCode: addressToEdit.postCode,
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption.value,
      countryFlagCode: selectedOption.code, // Store the selected country's flag code
    }));
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const [openConfirmation, setOpenConfirmation] = useState(false);
  // Show confirmation dialog when Close button is clicked
  const handleClose = () => {
    setOpenConfirmation(true); // Open the confirmation dialog
  };

  // Handle confirmation dialog close actions
  const handleConfirmClose = (confirm) => {
    if (confirm) {
      console.log('Closing modal');
      setOpenConfirmation(false);
      toggleModal();  // Close the modal (ShopOnlineModal)
    } else {
      console.log('Modal not closed');
      setOpenConfirmation(false); // Hide the confirmation dialog without closing the modal
    }
  };


  // Delete address function
  const handleDeleteAddress = async (index) => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const updatedAddresses = [...userData.shippingAddresses];
      updatedAddresses.splice(index, 1); // Remove the selected address
      await updateDoc(userDocRef, { shippingAddresses: updatedAddresses });
      setUserData((prevData) => ({
        ...prevData,
        shippingAddresses: updatedAddresses,
      }));
    }
  };

  //handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { receiverName, contactNumber, country, countryFlagCode, address, postCode } = formData;
  
    if (!receiverName || !contactNumber || !country || !countryFlagCode || !address || !postCode) {
      toast.error("Please fill in all the fields before proceeding.");
      return;
    }
  
    setLoading(true); // Start the loader when the form is submitted
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const newAddress = {
          receiverName,
          contactNumber,
          country,
          countryFlagCode, // Save the flag code
          address,
          postCode,
        };
  
        // Fetch current addresses
        const userSnapshot = await getDoc(userDocRef);
        let currentAddresses = userSnapshot.data()?.shippingAddresses || [];
  
        if (isEditMode) {
          // Update the existing address in Edit Mode
          currentAddresses[editIndex] = newAddress;
        } else {
          // Append the new address in Add Mode
          currentAddresses = [...currentAddresses, newAddress];
        }
  
        // Update Firestore
        await updateDoc(userDocRef, { shippingAddresses: currentAddresses });
  
        // Update local state
        setUserData((prevData) => ({
          ...prevData,
          shippingAddresses: currentAddresses,
        }));
  
        // Reset form and close modal
        setShowModal(false);
      }
    } catch (error) {
      console.error('Error adding/editing address:', error);
      toast.error('Failed to save address. Please try again.');
    } finally {
      setLoading(false); // Stop the loader after the process is done (success or error)
      toast.success('Address submitted successfully!');
    }
  };
  

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setUserData({
            userName: userData['User Name'] || '',
            userId: userData['User_ID'] || '',
            shippingAddresses: userData.shippingAddresses || [],
          });
        } else {
          console.log('No such document!');
        }
      } else {
        // Redirect to sign-in if not authenticated
        window.location.href = '/signin';
      }
      setLoading(false); // Hide loader once data is fetched
    };

    fetchUserData();
  }, []);

  return (
    <div className='address-main-container'>
      {loading ? (
        <div className="loader-overlay">
        <div className="loader"></div>
        </div>
      ) : (
    <div className='addressbook-parent'>
      <div className="address-book-container">
        <div className="welcome-section-address">
          <h1>Address Book</h1>
          <div className='AddNewButton'>
            <button
              title="Add New Address"
              className="group cursor-pointer outline-none hover:rotate-90 duration-300"
              onClick={handleAddNewAddress}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
                className="stroke-green-100 fill-none group-hover:fill-green-500 
                group-active:stroke-green-200 group-active:fill-green-200 group-active:duration-0 duration-300"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  strokeWidth="1.5"
                ></path>
                <path d="M8 12H16" strokeWidth="1.5"></path>
                <path d="M12 16V8" strokeWidth="1.5"></path>
              </svg>
            </button>
            <p>Add New Address</p>
          </div>
        </div>

        {/* Display all shipping addresses */}
        <div className={`shipping-addresses ${userData.shippingAddresses.length === 1 ? 
          'single-address' : userData.shippingAddresses.length === 2 ? 'two-addresses' : ''}`}>

          {userData.shippingAddresses.length > 0 ? (
            userData.shippingAddresses.map((address, index) => (
              <div
                key={index}
                className={`address-card-book ${
                  userData.shippingAddresses.length === 1 ? 'single' : ''
                }`}
              >
                {/* Delete icon positioned at the top-right */}
                <FaTimes
                  className="delete-icon"
                  onClick={() => handleDeleteAddress(index)}
                />

                <div className="address-content">
                  <center>
                  <img
                    src={`https://flagcdn.com/w320/${address.countryFlagCode}.png`} // Use the saved country flag code
                    alt={`${address.country} Flag`}
                    className="flag-icon"
                  />
                  </center>
                  <div className="address-details">
                    <p>
                      <strong>Name:</strong> {address.receiverName}
                    </p>
                    <p>
                      <strong>Address:</strong> {address.address}
                    </p>
                    <p>
                      {address.country}, {address.postCode}
                    </p>
                    <p>
                      <strong>Contact Number:</strong> {address.contactNumber}
                    </p>
                  </div>
                </div>
                <button
                  className="edit-btn" title='Edit'
                  onClick={() => handleEditAddress(index)}
                >
                  <lord-icon
                        src="https://cdn.lordicon.com/fikcyfpp.json"
                        trigger="hover"
                        stroke="bold"
                        colors="primary:#ffffff,secondary:#ffffff"
                        state="hover-slide"
                        style={{ width: '30px', height: '30px' }}>
                  </lord-icon> 
                </button>
              </div>
            ))
          ) : (
            
            <div className="info-box-address">
            <div className="info-icon-address">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm.93-7a.5.5 0 0 1 .992.09l-.447 4.005a.552.552 0 0 1-1.095 0L7.072 4.09a.5.5 0 0 1 .86-.09z"/>
              </svg>
            </div>
            <p>No shipping addresses found</p>
          </div>
            
            
          )}
        </div>

        {/* Modal for adding/editing an address */}
        {showModal && (
          <div className="Onlinemodal-overlay">
            
            <div className="Onlinemodal-details">
            <button className="close-modal" onClick={handleClose}>
          ×
        </button>
              <div className="modal-header">
                <h2>{isEditMode ? 'Edit Address' : 'Add New Address'}</h2>
                
              </div>
              <form onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="input-group-address">
                    <label>Receiver's Full Name</label>
                    <input
                      type="text"
                      name="receiverName"
                      value={formData.receiverName}
                      onChange={handleInputChange}
                      placeholder="Enter receiver's name"
                      
                    />
                  </div>

                  <div className="input-group-address">
                    <label>Contact Number</label>
                    <div className="input-flex">
                      <select 
                            className='code' 
                            name="countryCode" 
                            defaultValue="MV +960"
                            style={{width:"20%", textAlign:"center",cursor:"pointer", marginRight:"10px"}}>
                        <option value="MV +960">MV +960</option>
                       
                      </select>
                      <input
                        type="text"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        placeholder="Enter phone number"
                        
                      />
                    </div>
                  </div>

                  {/* Updated Dropdown for Country with Flags */}
                  <div className="input-group-address">
                    <label>Country</label>
                    <Select
                      
                      options={countryOptions}
                      onChange={handleCountryChange}
                      value={countryOptions.find(
                        (option) => option.value === formData.country
                      )}
                      placeholder="Select country ..."
                      isSearchable={true}
                      className="country-select"
                    />
                  </div>

                  <div className="input-group-address">
                    <label>Receiver's Address</label>
                    <input
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      placeholder="House No, Street Name"
                      
                    />
                  </div>

                  <div className="input-group-address">
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="postCode"
                      value={formData.postCode}
                      onChange={handleInputChange}
                      placeholder="Enter postal code"
                      
                    />
                  </div>
                </div>

                <div className="formBtns">
                  <button type="submit" className="submitFormBtn">
                    {isEditMode ? 'Update' : 'Add Address'}
                  </button>
                  <button
                    type="button" // Ensure the button doesn't act as a submit button
                    className="closeFormBtn"
                    onClick={handleClose}
                  >
                    Cancel
                </button>
                </div>
              </form>
            </div>
          </div>
        )}
        
      </div>
      
    </div>
      )}
      
    <Footer/>
    {/* Material UI Confirmation Dialog */}
    <Dialog 
        open={openConfirmation} 
        onClose={() => setOpenConfirmation(false)}
        TransitionProps={{ timeout: 500 }}
        PaperProps={{
          style: {
            backgroundColor: '#fff',
            borderRadius: '35px',
            borderTop: '7px solid #0056B3',
            padding: '20px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          },
        }}>
        <DialogTitle style={{ fontFamily: "Segoe UI", fontSize: "28px" }}>Confirm to Close</DialogTitle>
        <DialogContent>
          You might lose unsaved data.<br/>Are you sure you want to close the form?
        </DialogContent>
        <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button 
            style={{
              backgroundColor: "#0056B3", 
              borderRadius: "25px", 
              color: "white", 
              textTransform: "none", 
              fontFamily: "Segoe UI", 
              transition: "background-color 0.3s ease"
            }}
            onClick={() => handleConfirmClose(true)} 
            color="primary">
            Yes
          </Button>
          <Button 
            style={{
              backgroundColor: "#DD3F3F", 
              borderRadius: "25px", 
              color: "white", 
              textTransform: "none", 
              fontFamily: "Segoe UI"
            }}
            onClick={() => handleConfirmClose(false)} 
            color="secondary">
            No
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </div>
    
  );
};

export default AddressBook;
