import React, { useState } from 'react';
import './check_rates.css';
import { useNavigate } from 'react-router-dom'; // Import the navigate hook
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Import Firebase Auth functions
import Header from '../../components/Header/Header';


const CheckRates = () => {
  const [fromCountry, setFromCountry] = useState('Australia');
  const [toCountry, setToCountry] = useState('Maldives');
  const [weight, setWeight] = useState(''); // For actual weight
  const [length, setLength] = useState(''); // For volumetric length
  const [width, setWidth] = useState(''); // For volumetric width
  const [height, setHeight] = useState(''); // For volumetric height
  const [volumetricWeight, setVolumetricWeight] = useState(0); // To store the calculated volumetric weight
  const [priceMVR, setPriceMVR] = useState(0);
  const [sheetRate, setSheetRate] = useState(0); // For Google Sheet Rate
  const [regularRates, setRegularRates] = useState([]); // Store dynamic regular rates per kg

 
  const API_KEY = 'AIzaSyCdDHnrqB4oh00AZLPFT9Cu2iezsAqhB-o'; // Your Google Sheets API key
  const SHEET_ID = '1Vh7hJnViAnV4B5jhXGyLoq23XKSGiI0sh4SFwntaoxA'; // Your Google Sheet ID
  const SHEET_RANGE = 'Sheet1'; // Range in your Google Sheet


  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

const countries = [
  { value: 'Australia', label: 'Australia' },
  { value: 'Canada', label: 'Canada' },
  { value: 'China', label: 'China' },
  { value: 'India', label: 'India' },
  { value: 'Japan', label: 'Japan' },
  { value: 'Malaysia', label: 'Malaysia' },
  { value: 'Singapore', label: 'Singapore' },
  { value: 'SriLanka', label: 'SriLanka' },
  { value: 'UAE', label: 'United Arab Emirates' },
  { value: 'UK', label: 'United Kingdom' },
  { value: 'USA', label: 'United States' },
];

const handleDropdownClick = () => setIsDropdownOpen(!isDropdownOpen);

const handleCountrySelect = (country) => {
  setFromCountry(country);
  setIsDropdownOpen(false);
};


  const countryFlags = {
    Malaysia: 'https://flagcdn.com/w320/my.png',
    Singapore: 'https://flagcdn.com/w320/sg.png',
    SriLanka:'https://flagcdn.com/w320/lk.png',
    India: 'https://flagcdn.com/w320/in.png',
    UAE: 'https://flagcdn.com/w320/ae.png',
    USA: 'https://flagcdn.com/w320/us.png',
    Australia: 'https://flagcdn.com/w320/au.png',
    Canada: 'https://flagcdn.com/w320/ca.png',
    China: 'https://flagcdn.com/w320/cn.png',
    Japan: 'https://flagcdn.com/w320/jp.png',
    UK: 'https://flagcdn.com/w320/gb.png',
    Maldives: 'https://flagcdn.com/w320/mv.png',
  };

  const handleFromCountryChange = (event) => {
    setFromCountry(event.target.value);
  };

  const handleToCountryChange = (event) => {
    setToCountry(event.target.value);
  };


  

  const handleWeightChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setWeight(value);
    }
  };

  const handleDimensionChange = (setter) => (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };
  const navigate = useNavigate();
  const auth = getAuth(); // Get the authentication instance

  // Function to handle "Shop With Us" button click
  const handleShopWithUsClick = () => {
    // Check if a user is currently signed in
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, navigate to the dashboard
        navigate('/dashboard');
      } else {
        // User is not signed in, navigate to the sign-in page
        navigate('/signin');
      }
    });
  };

  const roundUpWeight = (weight) => {
    if (weight > 70) {
      return weight; // For weight greater than 70, return as is
    }
  
    const weightBands = [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
      10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18,
      18.5, 19, 19.5, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
      56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70,
    ];
  
    // Use find to get the first weightBand greater than or equal to the weight
    return weightBands.find((band) => weight <= band) || weightBands[weightBands.length - 1];
  };
  

  // Volumetric Weight Calculation (L x W x H) / 5000 for example
  const calculateVolumetricWeight = () => {
    const divisor = 5000; // Adjust divisor as needed
    if (length && width && height) {
      const volWeight = (length * width * height) / divisor;
      setVolumetricWeight(volWeight.toFixed()); // Update state with volumetric weight
      return volWeight;
    } else {
      setVolumetricWeight(0); // Reset to 0 if dimensions are missing
      return 0;
    }
  };
  
  
  
  const calculatePrice = async () => {
    const baseWeight = parseFloat(weight);
    const calculatedVolumetricWeight = calculateVolumetricWeight();
    let finalWeight;
  
    if (baseWeight && calculatedVolumetricWeight) {
      // Use the greater of actual weight or volumetric weight
      finalWeight = Math.max(baseWeight, calculatedVolumetricWeight);
    } else if (baseWeight) {
      finalWeight = baseWeight;
    } else if (calculatedVolumetricWeight) {
      finalWeight = calculatedVolumetricWeight;
    } else {
      alert("Please enter either Actual Weight or Length, Width, and Height.");
      return;
    }
  
    // If weight <= 70, round it to the nearest weight band
    if (finalWeight <= 70) {
      finalWeight = roundUpWeight(finalWeight);
    }
  
    // Fetch rates based on the final weight
    if (finalWeight > 70) {
      await fetchGoogleSheetRateAbove70(finalWeight);
    } else {
      await fetchGoogleSheetRate(finalWeight);
    }
    
  };
  
  
  const fetchGoogleSheetRateAbove70 = async (actualWeight) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;

      console.log('Number of rows read:', rows.length);

      // Find the row for 70+ rate
      const matchingRow = rows.find(
        (row) => row[1] === '70 +' && row[3] === fromCountry && row[0] === 'Non- Document'
      );
      

      if (matchingRow) {
        const ratePerKg = parseFloat(matchingRow[2]); // Get the rate for 70+
        const mvrRatePerKg = parseFloat(matchingRow[5]); // Get the MVR rate for 70+

        // Calculate USD and MVR based on actual weight
        const calculatedPriceUSD = (ratePerKg * actualWeight).toFixed(2);
        const calculatedPriceMVR = (mvrRatePerKg * actualWeight).toFixed(2);

        // Set the rates for display
        setSheetRate(calculatedPriceUSD);
        setPriceMVR(calculatedPriceMVR);
      } else {
        setSheetRate('Rate not available for this weight and type.');
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }
  };

  const fetchGoogleSheetRate = async (roundedWeight) => {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_RANGE}?key=${API_KEY}`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      const rows = data.values;

      console.log('Number of rows read:', rows.length);

      // Find the row that matches the rounded weight, country, and 'Non-Document' type
      const matchingRow = rows.find(
          (row) =>
              parseFloat(row[1]) === parseFloat(roundedWeight) && // Ensure both are numbers
              row[3] === fromCountry &&
              row[0] === 'Non- Document'
      );
      

      if (matchingRow) {
        console.log('Matching row:', matchingRow);

        const ratePerKg = parseFloat(matchingRow[2]); // USD rate per kg
        const mvrRatePerKg = parseFloat(matchingRow[5]); // MVR rate per kg

        console.log("MVR Rate per kg: ", mvrRatePerKg);

        // Calculate and set the rates
        const calculatedPriceUSD = (ratePerKg).toFixed(2); // Round to 2 decimal places
        const calculatedPriceMVR = (mvrRatePerKg).toFixed(2); // Round to 2 decimal places

        console.log("MVR: ", calculatedPriceMVR)
        setSheetRate(calculatedPriceUSD);
        setPriceMVR(calculatedPriceMVR);

        updateRegularRates(rows, fromCountry);
       
    
      } else {
        console.warn('No matching row found for the given criteria.');
        setSheetRate('Rate not available for this weight and type.');
        setRegularRates([]); // Clear rates if no match
      }
    } catch (error) {
      console.error('Error fetching Google Sheets data:', error);
    }

  
  };



  const updateRegularRates = (rows, country) => {
    // Fetch rates from Google Sheet rows for different weight bands, country, and "Non-Document" type
    const weightBands = [2, 5, 10, 45]; // Adjust the weight bands as per your needs
    const rates = weightBands.map((band) => {
      const matchingRow = rows.find(
        (row) => parseFloat(row[1]) === band && row[3] === country && row[0] === 'Non- Document'
      );
      return matchingRow ? parseFloat(matchingRow[2]) : 'N/A';
    });

    setRegularRates(rates);
  };

 
  return (
   
  <div className="check-rates-container-main">
    <Header />

    <div className="check-rates-container">
      <div className="check-rates-header">
        <h1 className="h1_check">Calculate Rates</h1>
      </div>

      <div className="rate-form">
        {/* Country Selection */}
        <div className="form-group">
          <label htmlFor="fromCountry">FROM</label>
          <div className="country-select-rates" onClick={handleDropdownClick}>
            <div className="dropdown-header">
              <img src={countryFlags[fromCountry]} alt={fromCountry} className="flag" />
              <span className="country-name">{fromCountry}</span>
              <span className="arrow">▼</span>
            </div>
            <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
              {countries.map((country) => (
                <li
                  key={country.value}
                  className="dropdown-item"
                  onClick={() => handleCountrySelect(country.value)}
                >
                  <img src={countryFlags[country.value]} alt={country.label} className="flag" />
                  <span className="country-name">{country.label}</span>
                </li>
              ))}
            </ul>
          </div>


        </div>

        {/* Arrow Icon */}
        <div className="arrow-down">
          <lord-icon
            src="https://cdn.lordicon.com/vduvxizq.json"
            trigger="loop"
            delay="1000"
            stroke="bold"
            colors="primary:#0f2853" 
            style={{ width: '55px', height: '55px' }}>
          </lord-icon>
        </div> 

        <div className="form-group">
          <label htmlFor="toCountry">TO</label>
          <div className="toCountrycontainer">
  <img src={countryFlags[toCountry]} alt={toCountry} className="flag" />
  <span className="country-name">{toCountry}</span>
</div>  
        </div>
      </div>

      {/* Combined Weight Section */}
<div className="weight-section">
      <p className="info-message-weight">
      Select your origin country, enter the package weight, and click 'Calculate' to receive the applicable rates for your shipment.
      <br/>
        Weight will be determined by either Actual or Volumetric Weight (VW) or whichever is higher.
        Confirmed freight charges will be emailed once we receive the package/items.
      </p>

  {/* Actual Weight Input */}
  

  {/* Volumetric Weight Input */}
  <div className="weightGuide">
  <div className='weightmeasureGuide'>
      <img src={`${process.env.PUBLIC_URL}/images/volCal.png`} alt="Dimension Guide"  />
    <div className="calPanel">
      <div className="weight-input-section">
        <div className='actualWeight-container'>
        <label>Actual Weight (kg)</label>
        <input
          type="text"
          value={weight}
          onChange={handleWeightChange}
          placeholder="Enter actual weight"
          className="weight"
        />
        </div>
        <div className='volWeight_inputSection'>
              <div className="input-row">
                <label>L (cm)</label>
                <input
                  type="text"
                  value={length}
                  onChange={handleDimensionChange(setLength)}
                  placeholder="Length"
                />
                <label>W (cm)</label>
              <input
                type="text"
                value={width}
                onChange={handleDimensionChange(setWidth)}
                placeholder="Width"
              />
              <label>H (cm)</label>
              <input
                type="text"
                value={height}
                onChange={handleDimensionChange(setHeight)}
                placeholder="Height"
              />
              </div> 
              
              
            
      </div>
       
    <div className="volumetric-input-section">
          <button onClick={calculatePrice} className="calculate-button">
                Calculate
          </button>
      <div className="volumetric-weight-result">
          <div className='volumetric'>
              <label>Volumetric Weight (kg)</label>
              <input type="text" value={volumetricWeight} readOnly/>
          </div>
          <div className="billable">
              <label>Billable Weight (kg)</label>
              <input type="text" 
              style={{border:"2px solidrgb(9, 170, 68)"}}
              value={Math.max(parseFloat(weight || 0), parseFloat(volumetricWeight || 0))}
              readOnly/>
          </div>
      </div>
      <div className="price-item">
        <img src={countryFlags['USA']} alt="USD" className="flag"/>
        {/*USD {sheetRate}*/}
        <span className="updated-price">
    USD {(sheetRate * 1.32).toFixed(2)}
    </span>
        </div>
        <div className="price-item">
          <img src={countryFlags['Maldives']} alt="MVR" className="flag"/>
          {/*MVR {priceMVR}*/}
          <span className="updated-price">
      MVR {(priceMVR * 1.32).toFixed(2)}
    </span>
      </div>

      <button className="shopBtn" onClick={handleShopWithUsClick}>
        Ship With Us
      </button>
    </div>

      </div>
    </div>

  </div>


  </div>
  <div className='surcharge' style={{display:"flex", justifyContent:"center"}}>
            <p>The above rate excludes fuel surcharge, which will be added to your final bill.
            <a href="https://www.ups.com/lk/en/support/shipping-support/shipping-costs-rates/fuel-surcharges.page" 
                target="_blank" rel="noopener noreferrer"> Click here</a> for rate details.</p>
        </div>
</div>

      </div>

      
    </div>
    
  );
};

export default CheckRates;
