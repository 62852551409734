import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './header.css';
import { auth } from '../../firebase/Firebase';
import 'lord-icon-element';

const Header = ({ isAdmin }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();
  const [scrolled, setScrolled] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [userExist, setUserExist] = useState(false);

  // Define admin email
  const adminEmail = 'admin@example.com';

  // Detect when the user scrolls and change header background color
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Toggle dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Toggle mobile menu visibility
  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  // Hide dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Fetch the logged-in user's email from Firebase Authentication
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setUserExist(true);
      setUserEmail(user.email);
    } else if (location.pathname !== '/check-rates') {
      navigate('/signin');
      setUserExist(false);
    } else if (location.pathname === '/check-rates'){
      setUserExist(false);
    }
  }, [navigate, location.pathname]);

  const handleSignOut = () => {
    auth.signOut().then(() => {
      console.log('User signed out successfully');
      navigate('/');
    }).catch(error => console.error('Error signing out:', error));
  };

  // Function to determine if the link is active
  const isActiveLink = (path) => location.pathname === path;

  return (
      <header className={`header ${scrolled ? 'header-scrolled' : ''}`}>
        <div className="logo">
          <Link to="/home">
            <img
                src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp"
                alt="Header Logo"
            />
          </Link>
        </div>

        {/* Burger Menu Icon for Mobile */}
        <div className="burger-menu" onClick={toggleMobileMenu}>
          <i className="fas fa-bars"></i>
        </div>

        <nav className={`nav-container ${mobileMenuVisible ? 'show-menu' : ''}`}>
          <nav className="nav-links">
            {!isAdmin ? (
                <>
                  <a
                      href="https://fhl.ddb.myftpupload.com/"
                      className={`nav-item ${isActiveLink('/home') ? 'active' : ''}`}
                      rel="noopener noreferrer"
                  >
                    Home
                  </a>

                  {userExist && (
                      <Link
                          to="/profile"
                          className={`nav-item ${isActiveLink('/profile') ? 'active' : ''}`}
                      >
                        Profile
                      </Link>
                  )}
                  {userExist && (
                      <Link
                          to="/dashboard"
                          className={`nav-item ${isActiveLink('/dashboard') ? 'active' : ''}`}
                      >
                        Dashboard
                      </Link>
                  )}
                  <a
                      href="https://fhl.ddb.myftpupload.com/about-us/"
                      className={`nav-item ${isActiveLink('/about-us') ? 'active' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                  <a
                      href="https://fhl.ddb.myftpupload.com/contact-us/"
                      className={`nav-item ${isActiveLink('/contact-us') ? 'active' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    Contact
                  </a>
                  {!userExist && (
                      <Link
                          to="/signin"
                          className={`nav-item ${isActiveLink('/signin') ? 'active' : ''}`}
                      >
                        <lord-icon
                            src="https://cdn.lordicon.com/kdduutaw.json" // URL to the specific icon animation
                            trigger="hover"
                            colors="primary:#121331,secondary:#08a88a"
                            style={{ width: 19, height: 19, marginRight: 10 }}
                        ></lord-icon>
                        Sign In
                      </Link>
                  )}
                </>
            ) : (
                <>
                  <Link
                      to="/admin"
                      className={`nav-item ${isActiveLink('/admin') ? 'active' : ''}`}
                  >
                    Requests
                  </Link>
                  <a
                      href="https://fhl.ddb.myftpupload.com/about-us/"
                      className={`nav-item ${isActiveLink('/about-us') ? 'active' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    About Us
                  </a>
                  <a
                      href="https://fhl.ddb.myftpupload.com/contact-us/"
                      className={`nav-item ${isActiveLink('/contact-us') ? 'active' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    Contact
                  </a>
                </>
            )}
          </nav>

          {userExist && (
              <nav className="nav-icons">
                <div className="profile-menu" onClick={toggleDropdown}>
                  <lord-icon
                      src="https://cdn.lordicon.com/kdduutaw.json"
                      trigger="hover"
                      stroke="bold"
                      state="hover-looking-around"
                      colors="primary:#1f468b,secondary:#1f468b"
                      style={{ width: '35px', height: '35px' }}
                  ></lord-icon>
                  <i className="fas fa-caret-down"></i> {/* Dropdown caret */}
                </div>
              </nav>
          )}
        </nav>

        {dropdownVisible && (
            <div ref={dropdownRef} className="dropdown">
              <p className="signed-in-as">
                Signed in as <span>{userEmail}</span>
              </p>
              <hr className="dropdown-separator" />
              <div className="dropdown-links">
                <Link to="/profile" className="dropdown-item">Profile</Link>
                <Link to="/dashboard" className="dropdown-item">Dashboard</Link>
                <Link to="/archived-shipments" className="dropdown-item">Archived Shipments</Link>
                <Link to="/address-book" className="dropdown-item">Address Book</Link>
                <Link to="/check-rates" className="dropdown-item">Check Rates</Link>
                {userEmail === adminEmail && (
                    <Link to="/admin" className="dropdown-item">Admin Panel</Link>
                )}
              </div>
              <center>
                <button className="sign-out" title="Sign Out" onClick={handleSignOut}>
                  <lord-icon
                      src="https://cdn.lordicon.com/hmqxevgf.json"
                      trigger="hover"
                      stroke="bold"
                      state="hover-slide"
                      colors="primary:#adcae8,secondary:#adcae8"
                      style={{ width: '30px', height: '30px' }}
                  ></lord-icon>
                </button>
              </center>
            </div>
        )}
      </header>
  );
};

export default Header;