import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/Firebase'; // Import your Firebase configuration
import { collection, updateDoc, doc, query, where, onSnapshot, orderBy, addDoc, getDocs, deleteDoc } from 'firebase/firestore';
import './AdminPanel.css';
import Select from 'react-select';
import Footer from '../../components/footer/Footer';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCreation from '../../components/Admin_Creation_Popup/Admin_Creation';
import RejectModal from "../../components/Reject_Modal/rejectModal";
import UsersTab from "../../components/User_Tab/UsersTab";
import { auth } from "../../firebase/Firebase.js";
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { Timestamp } from "firebase/firestore";





import Swal from 'sweetalert2';



const AdminPanel = () => {

  
  const [usersMap, setUsersMap] = useState({});
  const [shoppingRequests, setShoppingRequests] = useState([]);
  const [packageItems, setPackageItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [newRequest, setNewRequest] = useState({
    userId: '',
    requestName: '',
    weight: '',
    Service_type: '',
    length: '',
    width: '',
    height: '',
    volumetricWeight: '',
    status: 'Pending',
    packageType: '',
    warehouseLocation: '',
    orderId: '',
    trackingNumber: '',
  });
  const [userSuggestions, setUserSuggestions] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isShoppingModalOpen, setIsShoppingModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('shoppingRequests');
  const [searchTerm, setSearchTerm] = React.useState("");
  const [shippingItems, setShippingItems] = useState([]);
  const [loadingShipment, setLoadingShipment] = useState(true);
  
  
//user tab
const [allUsers, setAllUsers] = useState([]);
const [filteredUsers, setFilteredUsers] = useState([]);
const [userSearch, setUserSearch] = useState("");
const [selectedUser, setSelectedUser] = useState(null);

// Get today's date in 'YYYY-MM-DD' format
const today = new Date().toISOString().split('T')[0];


//realtime user search and date filter
const [searchQuery, setSearchQuery] = useState('');
const [startDate, setStartDate] = useState('');
const [endDate, setEndDate] = useState('');

// Function to filter packages
const filterPackages = () => {
  return packageItems.filter((request) => {
    // Filter by search query (case-insensitive)
    const matchesSearchQuery =
      request.Order_ID.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.Tracking_Number.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.User_Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.Package_Type.toLowerCase().includes(searchQuery.toLowerCase());

    // Filter by date range
    const matchesDateRange = () => {
      if (!startDate && !endDate) return true;
      const requestDate = new Date(request.createdAt.seconds * 1000);
      const matchesStartDate = startDate ? requestDate >= new Date(startDate) : true;
      const matchesEndDate = endDate ? requestDate <= new Date(endDate) : true;
      return matchesStartDate && matchesEndDate;
    };

    return matchesSearchQuery && matchesDateRange();
  });
};



const [selectedWarehouseLocation, setSelectedWarehouseLocation] = useState("");
const [selectedPackageStatus, setSelectedPackageStatus] = useState("");
const [searchCustomerId, setSearchCustomerId] = useState("");
  const [shopOnlineItems, setShopOnlineItems] = useState({});
  const [itemCounts, setItemCounts] = useState({}); // Add this to your component

// Filtered Data
const filteredItems = shippingItems.filter((item) => {
  const matchesWarehouse =
    selectedWarehouseLocation === "" || item.warehouse_location === selectedWarehouseLocation;
  const matchesStatus =
    selectedPackageStatus === "" || item.Status === selectedPackageStatus;
  const matchesCustomerId =
    searchCustomerId === "" || (item.Customer_ID && item.Customer_ID.toString().includes(searchCustomerId));

  return matchesWarehouse && matchesStatus && matchesCustomerId;
});


// Reset Filters
const resetFilters = () => {
  setSelectedWarehouseLocation("");
  setSelectedPackageStatus("");
  setSearchCustomerId("");
};

// Date filter change handlers
const handleStartDateChange = (e) => {
  const selectedStartDate = e.target.value;
  // Check if the selected start date is later than the end date
  if (endDate && new Date(selectedStartDate) > new Date(endDate)) {
    toast.warning("Start date cannot be later than End date");
    return;
  }
  setStartDate(selectedStartDate);
};

const handleEndDateChange = (e) => {
  const selectedEndDate = e.target.value;
  // Check if the selected end date is earlier than the start date
  if (startDate && new Date(selectedEndDate) < new Date(startDate)) {
    toast.warning("End date cannot be earlier than Start date");
    return;
  }
  setEndDate(selectedEndDate);
};

// Search query change handler
const handleSearchChange = (e) => setSearchQuery(e.target.value);

// Function to clear filters
const clearFilters = () => {
  setSearchQuery('');
  setStartDate('');
  setEndDate('');
};

// Render filtered packages
const filteredPackageItems = filterPackages();

// Admin Creation Popup
const [isAdminCreationOpen, setIsAdminCreationOpen] = useState(false);

const handleCreateAdminClick = () => {
  setIsAdminCreationOpen(true);
};

const handleCloseAdminCreation = () => {
  setIsAdminCreationOpen(false);
};

//Confirmation box for the "mark as received btn"
const [isDialogOpen, setIsDialogOpen] = useState(false);
const [selectedItemId, setSelectedItemId] = useState(null); // Store the ID of the selected item

const handleConfirmAction = () => {
  if (selectedItemId) {
    handleUpdateStatus(selectedItemId); // Call your update function
  }
  setIsDialogOpen(false); // Close the dialog
};

const handleCloseDialog = () => {
  setIsDialogOpen(false); // Close the dialog without action
};


  useEffect(() => {
    const fetchUsersData = async () => {
      const usersCollectionRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersCollectionRef);

      // Map User_ID to email
      const usersData = {};
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        usersData[userData.User_ID] = userData.Email; // Map User_ID to Email
      });

      setUsersMap(usersData); // Save to state
    };

    fetchUsersData();
  }, []);


  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollectionRef = collection(db, "users");
      const querySnapshot = await getDocs(usersCollectionRef);
      const users = querySnapshot.docs.map((doc) => doc.data());

      setAllUsers(users);
      setFilteredUsers(users); // Show all users by default

      // Extract User_ID and User Name for suggestions
      const suggestions = users.map((user) => ({
        label: `${user["User Name"]} (${user.User_ID})`, // Display both User Name and User_ID
        value: user.User_ID, // Unique identifier
        userName: user["User Name"], // Additional info for the selected user
      }));
      setUserSuggestions(suggestions);
    };

    fetchUsers();
  }, []);

  const [selectedLocation, setSelectedLocation] = useState('');

  useEffect(() => {
    const fetchShopOnline = async () => {
      // if (userId) {
        let q = query(collection(db, 'ShopOnline'));

        // Apply Warehouse_Location filter only if selected
        // if (selectedLocation) {
        //   q = query(q, where('warehouseLocation', '==', selectedLocation));
        // }
        //
        // // Apply platform filter only if selected
        // if (selectedPlatform) {
        //   q = query(q, where('platform', '==', selectedPlatform));
        // }

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const shopOnlineItems = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setShopOnlineItems(shopOnlineItems);

          // Calculate additional details if needed
          const counts = calculateItemCountsByLocation(shopOnlineItems);
          setItemCounts(counts); // Update the state with calculated counts
        });

        return () => unsubscribe();
      // }
    };

    fetchShopOnline();
  }, [selectedLocation]);

  const calculateItemCountsByLocation = (items) => {
    return items.reduce((acc, item) => {
      const location = item.warehouseLocation || 'Unknown';
      acc[location] = (acc[location] || 0) + 1;
      return acc;
    }, {});
  };


  // Function to add 'https://' if it's not present
const getUrlWithProtocol = (url) => {
  // Check if the URL already starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`; // Prepend 'https://'
  }
  return url;
};

  const [bankDetails, setBankDetails] = useState([]); // State for storing bank details
  const [newBankDetail, setNewBankDetail] = useState({ accountNumber: '', bankName: '', currency: '' });
   // For adding a new bank detail

  const [orderIds, setOrderIds] = useState([]); // Add this state to store the order IDs

  const fetchOrderIds = async (userId) => {
  try {
    if (userId) {
      const orderQuery = query(collection(db, 'shopping_Requests'), where('Customer_ID', '==', userId));
      const querySnapshot = await getDocs(orderQuery);

      if (!querySnapshot.empty) {
        const orders = querySnapshot.docs.map((doc) => doc.data().Order_ID).filter(orderId => !!orderId); // Extract and filter Order_IDs
        if (orders.length > 0) {
          setOrderIds(orders);
        } else {
          setOrderIds([]); // Clear if no order IDs found
        }
      } else {
        setOrderIds([]); // Clear if no matching documents found
      }
    }
  } catch (error) {
    console.error('Error fetching Order_IDs:', error);
  }
};

  const handleUpdateStatus = async (id) => {
    try {
      const docRef = doc(db, 'ShopOnline', id);
      await updateDoc(docRef, {
        status: 'Received',
        statusUpdatedAt: Timestamp.now(),
      });
      toast.success('Status updated to Received!');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status.');
    }
  };

const [isRejectModalOpen, setIsRejectModalOpen] = useState(false); // State for modal
const [adminRemark, setAdminRemark] = useState(""); // To store remark from modal

//admin logout function 
const handleLogout = () => {
  // Firebase sign-out
  try {
    // Assuming Firebase authentication is used
    auth.signOut();
    toast.success("Successfully logged out.");
    // Redirect to signin page
    window.location.href = "/signin"; 
  } catch (error) {
    toast.error("Error during logout. Please try again.");
    console.error("Logout Error:", error);
  }
};


const openRequestModal = (request) => {
  setSelectedRequest(request);  // Set the selected request data
  setIsModalOpen(true);         // Open the modal
};



const closeModal = () => {
  setIsModalOpen(false);        // Close the modal
  setSelectedRequest(null);     // Clear the selected request data
};

//More info button popup on Shopping Request Table
const openShoppingRequestModal = (request) => {
  setSelectedRequest(request);  // Set the selected request data
  setIsShoppingModalOpen(true);  // Open the modal
};
const closeShoppingModal = () => {
  setIsShoppingModalOpen(false);        // Close the modal
  setSelectedRequest(null);     // Clear the selected request data
};


  useEffect(() => {
    const shoppingCollectionRef = collection(db, 'shopping_Requests');
    const q = query(shoppingCollectionRef, orderBy('createdAt', 'desc')); // Order by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setShoppingRequests(requests);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const shoppingCollectionRef = collection(db, 'Received_packages');
    const q = query(shoppingCollectionRef, orderBy('createdAt', 'desc')); // Order by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPackageItems(requests);
      console.log("Package Items: ", requests)
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Fetch bank details from Firestore
    const bankCollectionRef = collection(db, 'bank_Details');
    const unsubscribe = onSnapshot(bankCollectionRef, (snapshot) => {
      const banks = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBankDetails(banks);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      const usersCollectionRef = collection(db, "users");
      const querySnapshot = await getDocs(usersCollectionRef);
      const users = querySnapshot.docs.map((doc) => doc.data());

      // Create userSuggestions array
      const suggestions = users.map((user) => ({
        label: `${user["User Name"]} (${user.User_ID})`,
        value: user.User_ID,
        userName: user["User Name"],
      }));

      console.log("Fetched User Suggestions: ", suggestions); // Debug log
      setUserSuggestions(suggestions);
    };

    fetchUsers();
  }, []);


  const handleUserSelection = (selectedOption) => {
    console.log("Selected User: ", selectedOption);

    if (!userSuggestions || userSuggestions.length === 0) {
      console.error("User Suggestions are not populated.");
      return;
    }

    console.log("User Suggestions: ", userSuggestions);

    const selectedUser = userSuggestions.find(
        (user) => user.value === selectedOption.value
    );

    if (!selectedUser) {
      console.error("No matching user found for the selected User ID.");
      return;
    }

    console.log("Matched Selected User: ", selectedUser);

    setNewRequest({
      ...newRequest,
      userId: selectedOption.value,
      requestName: selectedUser.userName, // Auto-fill User Name
    });

    fetchOrderIds(selectedOption.value); // Fetch related Order IDs if needed
  };






  useEffect(() => {
    const shippingCollectionRef = collection(db, 'Shipping_items');
    const q = query(shippingCollectionRef, orderBy('createdAt', 'desc')); // Order by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const items = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // .filter((item) => item.Status !== "Complete"); // Filter items with Status not equal to "Complete"
  
      setShippingItems(items);
      setLoadingShipment(false);
    });
  
    // Clean up the subscription when the component is unmounted
    return () => unsubscribe();
  }, []);



 
const handleUserSearch = () => {
  if (!userSearch) {
    setFilteredUsers(allUsers); // Reset to all users if search is empty
    return;
  }

  const searchResults = allUsers.filter(
    (user) =>
      user.User_ID.toLowerCase().includes(userSearch.toLowerCase()) ||
      user["User Name"].toLowerCase().includes(userSearch.toLowerCase())
  );

  setFilteredUsers(searchResults);
};


const handleAddNewRequest = async () => {
  const {
    userId,
    weight,
    length,
    width,
    height,
    volumetricWeight,
    orderId,
    trackingNumber,
    warehouseLocation,
    packageType,
    requestName,
  } = newRequest;

  //validation logic for mandatory fields
  if (!packageType || packageType.trim() === "") return toast.error("Package Type is required!");
  if (!warehouseLocation || warehouseLocation.trim() === "")
    return toast.error("Warehouse Location is required!");
  if (!userId || userId.trim() === "") return toast.error("User ID is required!");
  if (!orderId || orderId.trim() === "") return toast.error("Order ID is required!");
  if (!trackingNumber || trackingNumber.trim() === "") return toast.error("Tracking Number is required!");
  if (!length || length <= 0) return toast.error("Length is required and must be greater than 0!");
  if (!width || width <= 0) return toast.error("Width is required and must be greater than 0!");
  if (!height || height <= 0) return toast.error("Height is required and must be greater than 0!");
  if (!weight || weight <= 0) return toast.error("Actual weight is required and must be greater than 0!");
  
  
 
  if (!requestName || requestName.trim() === "") return toast.error("Request Name is required!");

  // Calculate billable weight: the greater of volumetricWeight and actual weight
  const billableWeight = Math.max(volumetricWeight, weight);
  setLoading(true); // Show loader
  try {
    const userQuery = query(collection(db, "users"), where("User_ID", "==", userId));
    const userSnapshot = await getDocs(userQuery);

    if (!userSnapshot.empty) {
      const userDoc = userSnapshot.docs[0];
      const customerId = userDoc.data().User_ID;

      // Collection reference for Received Packages
      const packagesCollectionRef = collection(db, "Received_packages");

      await addDoc(packagesCollectionRef, {
        Package_Type: packageType,
        Warehouse_Location: warehouseLocation,
        Customer_ID: customerId,
        User_Name: requestName,
        Order_ID: orderId,
        Tracking_Number: trackingNumber,
        Length: length,
        Width: width,
        Height: height,
        Volumetric_Weight: (length * width * height) / 5000, // Volumetric weight calculation
        billable_weight: billableWeight,
        Actual_Weight: weight,
        createdAt: new Date(),
      });

      // Reset form fields after successful submission
      setNewRequest({
        userId: "",
        requestName: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        volumetricWeight: "",
        orderId: "",
        trackingNumber: "",
        warehouseLocation: "",
        packageType: "",
      });

      toast.success("Package added successfully!");
      setShowPopup(false);
    } else {
      toast.error("User not found. Please check the User_ID.");
    }
  } catch (error) {
    console.error("Error adding package:", error);
    toast.error("An error occurred while adding the package. Please try again.");
  }
};


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Handle user ID selection from the dropdown
  const handleUserIdChange = (selectedOption) => {
    const user = userSuggestions.find((option) => option.value === selectedOption.value);
    if (user) {
      setNewRequest((prev) => ({
        ...prev,
        userId: selectedOption.value,
        requestName: user.userName, // Set User Name automatically
      }));
      fetchOrderIds(selectedOption.value); // Fetch Order IDs based on the selected User_ID
    }
  };
  
  const handleReject = async () => {
    if (selectedRequest) {
      setIsRejectModalOpen(true); // Open the modal
    }
  };

  const handleRejectSubmit = async (remark) => {
    if (!selectedRequest) return;

    setIsRejectModalOpen(false); // Close the modal
    const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);
    let updatedStatus = "";

    // Determine the updated status
    if (
      selectedRequest.Status === "Agent Review Ongoing" ||
      selectedRequest.Status === "Pending"
    ) {
      updatedStatus = "Item Rejected";
    } else if (selectedRequest.Status === "Payment Verification Pending") {
      updatedStatus = "Payment Rejected";
    } else {
      alert("Invalid status for rejection.");
      return;
    }

    try {
      // Update the Firestore document
      await updateDoc(requestDocRef, {
        Status: updatedStatus,
        Admin_Remark: remark,
        statusUpdatedAt: Timestamp.now(),
      });
    
      // Show the toast
      toast.success(`Request status changed to '${updatedStatus}' with remark.`);
    
      // Close the popup after a short delay
      setTimeout(() => {
        handleClosePopup();
      }, 1000); // Enough time for the toast to appear
    } catch (error) {
      console.error("Error updating request status or adding remark:", error);
      toast.error("Error updating request status or adding remark. Please try again.");
    }
    
  };



  const handleRequestClick = async (request) => {
    try {
      // If the current status is already "test", skip updating the status
      if (request.Status === "Pending") {
        // Optionally, you can check for other statuses you want to skip
        const requestDocRef = doc(db, 'shopping_Requests', request.id);

        // Update the status to "test" only if it's not already "test"
        await updateDoc(requestDocRef, { Status: "Agent Review Ongoing", statusUpdatedAt: Timestamp.now(),});
        

        // Update the status in the local state
        const updatedRequests = shoppingRequests.map((r) =>
          r.id === request.id ? { ...r, Status: "Agent Review Ongoing" } : r
        );
        setShoppingRequests(updatedRequests);
      }

      // Set the selected request for the popup
      setSelectedRequest(request);

      // Show the popup
      setShowPopup(true);
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  const handleApprove = async () => {
    if (selectedRequest) {
      const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);

      try {
        // Update the status to "Payment Request Completed"
        await updateDoc(requestDocRef, {
          Status: "Payment Request Completed",
          statusUpdatedAt: Timestamp.now(),
        });
        toast.success("Request status changed to 'Payment Request Completed'");

          // Close the popup after a short delay
          setTimeout(() => {
            handleClosePopup();
          }, 1000); 
        
      } catch (error) {
        console.error("Error updating request status:", error);
        toast.error("Error updating request status. Please try again.");
      }
    }
  };

  const handleOrderID = async () => {
    // Prompt the admin to enter the Order_ID
    const orderId = prompt("Please enter the Order_ID:");

    if (orderId) {
      try {
        const requestDocRef = doc(db, "shopping_Requests", selectedRequest.id);

        // Update the shopping request with the entered Order_ID and change Status to "Request Completed"
        await updateDoc(requestDocRef, {
          Order_ID: orderId,
          Status: "Order Placed", // Change status to "Request Completed"
          statusUpdatedAt: Timestamp.now()
        });

        toast.success("Order_ID saved and status changed to 'Request Completed'!");
        // Close the popup after success
        handleClosePopup();
      } catch (error) {
        console.error("Error updating Order_ID or status:", error);
        toast.error("Error saving Order_ID or changing status. Please try again.");
      }
    } else {
      toast.warning("Order_ID cannot be empty.");
    }
  };

  const handleClosePopup = () => {
    setSelectedRequest(null);
    setShowPopup(false);
  };

  const handleUpdateRequest = async () => {
    if (selectedRequest) {
      // Validation for mandatory fields
      const missingFields = [];
      
      // Check for any null or empty values in Unit_price, QTY, Delivery_charge, and Tax_amount
      if (!selectedRequest.Unit_price || selectedRequest.Unit_price.some((price) => price === null || price === "")) {
        missingFields.push("Unit Price");
      }
      if (!selectedRequest.QTY || selectedRequest.QTY.some((qty) => qty === null || qty === "")) {
        missingFields.push("Quantity (QTY)");
      }
      if (!selectedRequest.Delivery_charge || selectedRequest.Delivery_charge.some((charge) => charge === null || charge === "")) {
        missingFields.push("Delivery Charge");
      }
      if (!selectedRequest.Tax_amount || selectedRequest.Tax_amount.some((tax) => tax === null || tax === "")) {
        missingFields.push("Tax Amount");
      }
  
      // If there are missing fields, show an error message and stop execution
      if (missingFields.length > 0) {
        toast.error(`The following fields are mandatory: ${missingFields.join(", ")}`);
        return;
      }
  
      const requestDoc = doc(db, 'shopping_Requests', selectedRequest.id);
  
      // Define the static status
      const staticStatus = "Payment for Items Pending";
  
      // Prepare the update data
      const updateData = {
        Status: staticStatus, // Set the static status
        Weight: selectedRequest.Weight,
        Unit_price: selectedRequest.Unit_price || [],
        QTY: selectedRequest.QTY || [],
        Total_price: selectedRequest.Total_price || [],
        Delivery_charge: selectedRequest.Delivery_charge || [],
        Tax_amount: selectedRequest.Tax_amount || [],
      };
  
      // Only add product_reference_url if it's not undefined
      if (selectedRequest.product_reference_url) {
        updateData.product_reference_url = selectedRequest.product_reference_url;
      }
  
      try {
        await updateDoc(requestDoc, updateData);
        setTimeout(() => {
          toast.success("Shopping request successfully updated!");
        }, 600);
        setTimeout(() => {
          toast.success("Status changed to 'Payment Pending'");
        }, 700);

        handleClosePopup(); // Close the popup after a successful update
      } catch (error) {
        console.error("Error updating request:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
  };


  const updateRequestStatus = async (requestId) => {
    if (requestId) {
      // Show the Swal confirmation dialog
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "This will update the status to 'Package Received'.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });
  
      if (result.isConfirmed) {
        const requestDoc = doc(db, 'shopping_Requests', requestId);
  
        // Define the static status
        const staticStatus = "Package Received";
  
        try {
          // Update only the Status field in the document
          await updateDoc(requestDoc, { Status: staticStatus });
          setTimeout(() => {
            toast.success("Status successfully updated to 'Package Received'!");
          }, 600);
        } catch (error) {
          console.error("Error updating status:", error);
          // Handle error (e.g., show an error message to the user)
          toast.error("Failed to update the status. Please try again.");
        }
      } else {
        Swal.fire('Cancelled', 'The status was not updated.', 'info');
      }
    }
  };



  // Function to handle adding new bank details
  const handleAddBankDetail = async () => {

  // Validation to check if fields are not blank
  if (!newBankDetail.accountNumber || !newBankDetail.bankName || !newBankDetail.currency) {
    toast.error("Please fill out all fields before adding the bank detail.");
    return;
  }

  // Show confirmation dialog
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "Do you want to add this bank account?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  if (result.isConfirmed) {
    try {
      setLoading(true); // Start loading
      const bankCollectionRef = collection(db, 'bank_Details');
      await addDoc(bankCollectionRef, newBankDetail);
      setNewBankDetail({ accountNumber: '', bankName: '', currency: '' }); // Reset the form
      toast.success("Bank account added successfully!");


    } catch (error) {
      console.error('Error adding bank detail:', error);
      toast.error('Failed to add bank account.');


    } finally {
      setLoading(false); // Stop loading once done
    }
  }
};


//Deleting existing bank detail
const handleDeleteBankDetail = async (bankId) => {
  // Show confirmation dialog
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: "This action cannot be undone. Do you want to delete this bank detail?",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No',
  });

  if (result.isConfirmed) {
    try {
      // Reference to the document in Firestore
      const bankDocRef = doc(db, 'bank_Details', bankId);

      // Delete the document
      await deleteDoc(bankDocRef);

      // Optionally, update the local state to remove the deleted bank detail
      setBankDetails((prev) => prev.filter((bank) => bank.id !== bankId));

      // Show success alert
      toast.success("The bank detail has been deleted.");
    } catch (error) {
      toast.error("Error deleting bank detail");


    }
  }
};


//Pending needs to be seen first, then sort according to the ordered date 
const sortedShopOnlineItems = Array.isArray(shopOnlineItems)
  ? [...shopOnlineItems].sort((a, b) => {
      if (a.status === b.status) {
        return new Date(a.orderDate) - new Date(b.orderDate); // Sort by orderDate
      }
      return a.status === "Pending" ? -1 : 1; // Prioritize "Pending"
    })
  : [];




  const updateShippingStatus = async (id, email) => {
    try {
      // Get the selected status from the dropdown using the unique ID
      const selectedStatus = document.getElementById(`statusSelect_${id}`).value;

      // Check if a valid status is selected
      if (selectedStatus === "Status" || !selectedStatus) {
        toast.warning("Please select a valid status.");
        return;
      }

      // Show SweetAlert2 confirmation dialog
      const result = await Swal.fire({
        title: 'Confirm Status Update',
        text: `Are you sure you want to update the status to "${selectedStatus}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Update',
        cancelButtonText: 'Cancel',
      });

      // If the user cancels, exit the function
      if (!result.isConfirmed) return;

      // Reference to the document in Firestore
      const requestDocRef = doc(db, 'Shipping_items', id);

      // Update the status in Firestore
      await updateDoc(requestDocRef, { Status: selectedStatus, statusUpdatedAt: Timestamp.now(), });

      // Update the status in the local state if necessary
      const updatedRequests = shippingItems.map((r) =>
          r.id === id ? { ...r, Status: selectedStatus } : r
      );
      setShippingItems(updatedRequests);

      // Show success message for status update
      toast.success("The status has been updated.");

      // Dynamic email content
      const userEmail = `${email}`; // Replace with the user's email fetched dynamically if available
      const emailSubject = `Status Update for Request #${id}`;
      const emailText = `Your request with ID #${id} has been updated to: ${selectedStatus}.`;
      const emailHtml = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Status Update</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
            margin: 0;
            padding: 0;
            background-color: #f9f9f9;
        }
        .email-container {
            width: 100%;
            max-width: 600px;
            margin: 20px auto;
            background: #fff;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
        }
        .header {
            background: #4CAF50;
            color: #fff;
            padding: 20px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
        }
        .content {
            padding: 20px;
        }
        .content p {
            margin: 10px 0;
        }
        .content .highlight {
            font-weight: bold;
            color: #4CAF50;
        }
        .footer {
            background: #f1f1f1;
            color: #666;
            text-align: center;
            font-size: 14px;
            padding: 10px;
        }
        .footer a {
            color: #4CAF50;
            text-decoration: none;
        }
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            Status Update Notification
        </div>
        <div class="content">
            <p>Dear <span class="highlight">Valued User</span>,</p>
            <p>Your request with the following details has been updated:</p>
            <p><strong>Request ID:</strong> <span class="highlight">#${id}</span></p>
            <p><strong>Updated Status:</strong> <span class="highlight">${selectedStatus}</span></p>
            <p>We encourage you to review the status of your request for further information. If you have any questions, feel free to reach out to our support team.</p>
            <p>Thank you for choosing our services!</p>
        </div>
        <div class="footer">
            <p>If you have any concerns, <a href="mailto:support@example.com">contact us</a>.</p>
            <p>&copy; ${new Date().getFullYear()} Your Company Name. All Rights Reserved.</p>
        </div>
    </div>
</body>
</html>
`;

      try {
        const emailResponse = await axios.post('http://email.fitscargoit.website/api/email/send', {
          to: userEmail,
          subject: emailSubject,
          text: emailText,
          html: emailHtml,
        });
        if (emailResponse.status >= 200 && emailResponse.status < 300) {
          toast.success(`Email notification sent successfully to ${email}`);
        } else {
          console.error('Unexpected response from email API:', emailResponse.status, emailResponse.data);
          toast.error("Failed to send email notification.");
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response from API:', error.response.data);
        } else {
          console.error('Error making API request:', error.message);
        }
      }


    } catch (error) {
      console.error('Error updating request or sending email:', error);
      toast.error("Failed to update status. Please try again.");
    }
  };




  // Function to update item status to "Complete"
  const updateItemStatus = async (itemId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to confirm this shipment?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const itemRef = doc(db, "Shipping_items", itemId);
          await updateDoc(itemRef, {
            Status: "Complete",
            statusUpdatedAt: Timestamp.now(),
          });
          toast.success(
            <div>
              The shipment <span style={{ fontWeight: 'bold' }}>{itemId}</span> has been confirmed.
            </div>
          );
        } catch (error) {
          console.error("Error updating status: ", error);
          toast.error(
            <div>
              The shipment <span style={{ fontWeight: 'bold' }}>{itemId}</span> cannot be confirmed. Please try again!.
            </div>
          )
        }
      }
    });
  };



//Search bar on Shopping Requests 
const filteredRequests = shoppingRequests.filter((request) => {
  const searchValue = searchTerm.trim().toLowerCase();

  // Safeguard against undefined values
  const requestId = (request.Request_id || "").toLowerCase();
  const requestName = (request.Request_name || "").toLowerCase();
  const customerId = String(request.customer_id || "").toLowerCase();
  const status = (request.Status || "").toLowerCase();

  return (
    requestId.includes(searchValue) ||
    requestName.includes(searchValue) ||
    customerId.includes(searchValue) ||
    status.includes(searchValue)
  );
});

//search by tracking no on shop online table
const [searchTrackingNo, setSearchTrackingNo] = useState("");
const filteredShopOnlineItems = Array.isArray(shopOnlineItems)
  ? shopOnlineItems.filter((item) => {
      const searchValue = searchTrackingNo.trim().toLowerCase();
      const trackingNo = String(item.trackingNo || "").toLowerCase();
      return trackingNo.includes(searchValue);
    })
  : [];


// Debugging Logs
console.log("Search Term:", searchTerm);
console.log("Shopping Requests:", shoppingRequests);
console.log("Filtered Requests:", filteredRequests);


  return (
  <div className='admin-panel-container-main'>
    <div className="logout-container">
    <button className="logOutBtn" title="Log Out" onClick={handleLogout}>
              <lord-icon
                src="https://cdn.lordicon.com/hmqxevgf.json"
                trigger="hover"
                stroke="bold"
                state="hover-slide"
                colors="primary:#ffffff,secondary:#ffffff"
                style={{ width: '35px', height: '35px' }}
              ></lord-icon>
    </button>
    </div>
    <div className="admin-panel-container">
      <div className='heading_admin'>
        <h1>Admin Panel</h1>
    <div className='tab-buttons-container'>
      <div className="tab-buttons">
        <button
            className={activeTab === 'shoppingRequests' ? 'active' : ''}
            onClick={() => setActiveTab('shoppingRequests')}
        >
          Shopping Requests
        </button>
        <button
            className={activeTab === 'users' ? 'active' : ''}
            onClick={() => setActiveTab('users')}
        >
          Users
        </button>
        <button
            className={activeTab === 'bankDetails' ? 'active' : ''}
            onClick={() => setActiveTab('bankDetails')}
        >
          Bank Details
        </button>

        <button
            className={activeTab === 'shippingItems' ? 'active' : ''}
            onClick={() => setActiveTab('shippingItems')}
        >
          New Shipment
        </button>

        <button
            className={activeTab === 'packagesView' ? 'active' : ''}
            onClick={() => setActiveTab('packagesView')}
        >
          View Packages
        </button>

        <button
            className={activeTab === 'shopOnline' ? 'active' : ''}
            onClick={() => setActiveTab('shopOnline')}
        >
          Shop Online
        </button>


        <button onClick={handleCreateAdminClick}>Create Admin</button>
        <div>

          <AdminCreation isOpen={isAdminCreationOpen} onClose={handleCloseAdminCreation}/>
        </div>
      </div>
    </div>
      </div>


      {activeTab === "shippingItems" && (
          <div className="admin-section">
            <h2>Shipping Items</h2>
            {loadingShipment ? (
                <div className="loader-overlay">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              {/* Filter/Search Options */}
              <div className="shippingfilter-container">
                <div className="filter-item">
                  <label htmlFor="warehouse-filter">Origin:</label>
                  <select
                    id="warehouse-filter"
                    value={selectedWarehouseLocation}
                    onChange={(e) => setSelectedWarehouseLocation(e.target.value)}
                  >
                    <option value="">All Locations</option>
                    {Array.from(
                      new Set(shippingItems.map((item) => item.warehouse_location))
                    ).map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="filter-item">
                  <label htmlFor="status-filter">Status:</label>
                    <select
                      id="status-filter"
                      value={selectedPackageStatus}
                      onChange={(e) => setSelectedPackageStatus(e.target.value)}
                    >
                              <option value="">All Statuses</option>
                              <option value="Order Received">Order received</option>
                              <option value="Processed Export">Processed for export</option>
                              <option value="In Transit">In transit</option>
                              <option value="Sorting Hub">
                                At the destination sorting hub
                              </option>
                              <option value="Customs Clearance">
                                Shipment undergoing customs clearance</option>
                              <option value="Delayed Weather">Delayed due to weather</option>
                              <option value="Shipment Delayed">Shipment delayed</option>
                              <option value="Customer Approval">
                                Waiting for customer’s approval
                              </option>
                              <option value="Out For Delivery">Out for delivery</option>
                              <option value="Delivered">Delivered</option>
                    </select>
                </div>

                <div className="filter-item">
                  <label htmlFor="customer-id-search">Customer ID:</label>
                  <input
                    id="customer-id-search"
                    type="text"
                    value={searchCustomerId}
                    onChange={(e) => setSearchCustomerId(e.target.value)}
                    placeholder="Search by Customer ID"
                  />
                </div>

                <button onClick={resetFilters} className="btnResetfilter">
                  Reset
                </button>
            </div>


              {/* Filtered Table */}
              <table className="admin-table">
                <thead>
                <tr>
                  <th>Package ID</th>
                  <th>Customer ID</th>
                  <th>Package Name</th>
                  <th>Current Status</th>
                  <th>Receiver Address</th>
                  <th>Total Billable Weight</th>
                  <th>Rate</th>
                  <th>Origin</th>
                  <th>Date Created</th>
                  <th>Request IDs</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                  {filteredItems.map((item) => (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.Customer_ID}</td>
                        <td>{item.packageName}</td>
                        <td>{item.Status}</td>
                        <td>{item.receiverAddress}</td>
                        <td>{item.total_billable_weight}</td>
                        <td>{item?.mvrRate ? `MVR ${item.mvrRate}` : null}</td>
                        <td>{item.warehouse_location}</td>
                        <td>
                          {item.createdAt
                              ? new Date(item.createdAt.seconds * 1000).toLocaleString()
                              : "N/A"}
                        </td>
                        <td>
                          {item.request_ids && item.request_ids.length > 0
                              ? item.request_ids.join(", ")
                              : "N/A"}
                        </td>
                        <td>
                          <div className="statusChange">
                            <select
                                id={`statusSelect_${item.id}`}
                                style={{borderRadius: "10px", width: "100%"}}
                            >
                              <option value="" disabled selected hidden>
                                Status
                              </option>
                              <option value="Order Received">Order received</option>
                              <option value="Processed Export">Processed for export</option>
                              <option value="In Transit">In transit</option>
                              <option value="Sorting Hub">
                                At the destination sorting hub
                              </option>
                              <option value="Customs Clearance">
                                Shipment undergoing customs clearance</option>
                              <option value="Delayed Weather">Delayed due to weather</option>
                              <option value="Shipment Delayed">Shipment delayed</option>
                              <option value="Customer Approval">
                                Waiting for customer’s approval
                              </option>
                              <option value="Out For Delivery">Out for delivery</option>
                              <option value="Delivered">Delivered</option>
                            </select>
                            <button
                                className="btnStatusUpdate"
                                onClick={() =>
                                    updateShippingStatus(item.id, usersMap[item.Customer_ID])
                                }
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
           </div>
      )}


{activeTab === 'shoppingRequests' && (
  <div className="admin-section">
    <h2>Shopping Requests</h2>

    {/* Search Bar */}
    <div className="search-section">
      <input
        type="text"
        placeholder="Search by User ID or Request Name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-bar"
      />
    </div>

    {loading ? (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    ) : (
      <table className="admin-table" style={{marginTop:"15px"}}>
        <thead>
        <tr>
          <th>Request ID</th>
          <th>Customer ID</th>
          <th>Request Name</th>
          <th>Current Status</th>
          <th>Date Created</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
  {filteredRequests.length > 0 ? (
    filteredRequests.map((request) => (
      <tr key={request.id}>
        <td>{request.Request_id}</td>
        <td>{request.customer_id}</td>
        <td>{request.Request_name}</td>
        <td>{request.Status}</td>
        <td>{request.createdAt ? new Date(request.createdAt.seconds * 1000).toLocaleString() : 'N/A'}</td>
        <td>
          <button onClick={() => handleRequestClick(request)}>
          <lord-icon
            src="https://cdn.lordicon.com/dicvhxpz.json"
            trigger="hover"
            delay="1000"
            stroke="bold"
            colors="primary:#0077B6,secondary:#0077B6"
            style={{ width: "30px", height: "30px" }}
          ></lord-icon>
          </button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="6" className="no-results">
        No requests found matching "{searchTerm}".
      </td>
    </tr>
  )}
</tbody>

      </table>
    )}
  </div>
)}



      {activeTab === 'packagesView' && (
          <div className="admin-section">
            
            <h2>Packages View</h2>
            <div className='newPackageBtnContainer'>
            <button
                className='newPackageBtn' 
                onClick={togglePopup}>Add New Packages</button>
            </div>
            <div className="filter-section">
                {/* Search Bar */}
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                />

              {/* Date Range Filter */}
              <div className="date-filter">
                <input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  max={today} // Disable future dates for the From date
                />
                <span>to</span>
                <input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  max={today} // Disable future dates for the To date
                />
                
              </div>
              {/* Clear Filters Button */}
        <div className='resetBtncontainer'>
          <button className="clear-filters" onClick={clearFilters}>
            Reset
          </button>
        </div>
          
        </div>

        
            {loading ? (
                <div className="loader-overlay">
                  <div className="loader"></div>
                </div>
            ) : (
                <table className="admin-table">
                  <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Tracking No.</th>
                    <th>Bill. Weight</th>
                    <th>User</th>
                    <th>Package Type</th>
                    <th>Requested Date</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredPackageItems.map((request) => (
                      <tr key={request.id}>
                        <td>

                          <a href="#" onClick={() => openRequestModal(request)}>
                            {request.Order_ID}
                          </a>

                        </td>
                        <td>{request.Tracking_Number}</td>
                        <td>{request.billable_weight}</td>
                        <td>{request.User_Name}</td>
                        <td>{request.Package_Type}</td>
                        
                        
                        <td>
                          {request.createdAt
                              ? new Date(request.createdAt.seconds * 1000).toLocaleString()
                              : 'N/A'}
                        </td>
                        <td>
                              <button className="viewPackInfo" onClick={() => openRequestModal(request)} >
                              <lord-icon
                                  src="https://cdn.lordicon.com/dicvhxpz.json"
                                  trigger="hover"
                                  stroke="bold"
                                  colors="primary:#0077B6,secondary:#0077B6"
                                  style={{ width: "30px", height: "30px" }}
                              ></lord-icon>
                              </button>
                        </td>

                      </tr>
                  ))}
                  </tbody>
                </table>
            )}
          </div>
      )}

      {/* Modal for Request Details */}
        {isModalOpen && selectedRequest && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={closeModal}>
                ×
              </button>
              <h2>Package Details</h2>
            <div className='contentBG'>
              <p><strong>Request ID:</strong> {selectedRequest.Order_ID}</p>
              <p><strong>Tracking No.:</strong> {selectedRequest.Tracking_Number}</p>
              <p><strong>Height:</strong> {selectedRequest.Height} cm</p>
              <p><strong>Length:</strong> {selectedRequest.Length} cm</p>
              <p><strong>Vol. Weight:</strong> {selectedRequest.Volumetric_Weight} Kg</p>
              <p><strong>Actual Weight:</strong> {selectedRequest.Actual_Weight} Kg</p>
              <p><strong>User:</strong> {selectedRequest.User_Name}</p>
              <p><strong>Package Type:</strong> {selectedRequest.Package_Type}</p>
              <p><strong>Requested Date:</strong> {selectedRequest.createdAt
                ? new Date(selectedRequest.createdAt.seconds * 1000).toLocaleString()
                : 'N/A'}</p>
            </div>
            </div>
          </div>
        )}


        {/* Modal for Shopping Request Details */}
        {isShoppingModalOpen && selectedRequest && (
          <div className="modal-overlay">
            <div className="modal-content">
              <button className="close-modal" onClick={closeShoppingModal}>
                ×
              </button>
              <h2>Package Details</h2>
            <div className='contentBG'>
              <p><strong>Request ID:</strong> {selectedRequest.Request_id}</p>
              <p><strong>Request Name:</strong> {selectedRequest.Request_name}</p>
              <p><strong>Status</strong> {selectedRequest.Status}</p>
              <p><strong>Requested Date:</strong> {selectedRequest.createdAt
                ? new Date(selectedRequest.createdAt.seconds * 1000).toLocaleString()
                : 'N/A'}</p>
            </div>
            </div>
          </div>
        )}


      {activeTab === 'bankDetails' && (
          <div className="admin-section">
            <h2>Bank Details</h2>

            {/* Add New Bank Details Form */}
            <div className="bank-details-form">
              <h3>Add New Bank Accounts</h3>
              <label>Account Number</label>
              <input
                  type="text"
                  placeholder="Enter your account number"
                  value={newBankDetail.accountNumber}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) { // Only allow digits
                      setNewBankDetail((prev) => ({...prev, accountNumber: value}));
                    }
              }}
            />
            <label>Bank Name</label>
            <input
              type="text"
              placeholder='Enter bank name and branch'
              value={newBankDetail.bankName}
              onChange={(e) => setNewBankDetail((prev) => ({ ...prev, bankName: e.target.value }))}
            />
            <label>Currency</label>
            <input
                type="text"
                placeholder="Enter currency in shortened form (Eg: AED)"
                value={newBankDetail.currency}
                onChange={(e) => {
                  const value = e.target.value.toUpperCase(); // Convert input to uppercase
                  if (/^[A-Z]{0,3}$/.test(value)) { // Only allow up to 3 uppercase letters
                    setNewBankDetail((prev) => ({ ...prev, currency: value }));
                  }
                }}
            />

            <button 
                  className='updateReq_btn' 
                  style={{marginTop:'15px', marginBottom:'45px'}} 
                  onClick={handleAddBankDetail}>Add Bank Detail</button>

                  {/* Show loader while loading */}
                  {loading && (
                    <div className="loader-overlay">
                      <div className="loader"></div>
                    </div>
                  )}
                              
          </div>
          

          {/* List Existing Bank Details */}
          <h3 className='existingTitle'> Existing Accounts</h3>
          <table className="admin-table">
            <thead>
              <tr>
                <th>Account Number</th>
                <th>Bank Name</th>
                <th>Currency</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {bankDetails.map((bank) => (
                <tr key={bank.id}>
                  <td>{bank.accountNumber}</td>
                  <td>{bank.bankName}</td>
                  <td>{bank.currency}</td>
                  <td>
                  <div className='removeBank'>
                    <button
                    className='removeBankBtn'
                    onClick={() => handleDeleteBankDetail(bank.id)}
                    >
                    <lord-icon
                      src="https://cdn.lordicon.com/hwjcdycb.json"
                      trigger="hover"
                      stroke="bold"
                      colors="primary:#E33F31,secondary:#E33F31"
                      state="hover-slide"
                      style={{ width: "30px", height: "30px" }}
                    ></lord-icon>
                    </button>
                  </div>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

{activeTab === 'users' && (


    <UsersTab allUsers={allUsers} />

)}


      {showPopup && (
        <div className="popup-modal">
          {loading && (
              <div className="loader-overlay">
                <div className="loader"></div>
              </div>
            )}
          <div className="popup-content">
            {selectedRequest ? (
              <>
              <button className="closeIcon" onClick={handleClosePopup}>
                  ×
              </button>       
                
                <h3>Request Details</h3>
                <label className='labeltext'>Request ID</label>
                <p className='popCO'>{selectedRequest.Request_id}</p>

                <label className='labeltext'>Request Name</label>
                <p className='popCO'>{selectedRequest.Request_name}</p>

                <label className='labeltext'>Status</label>
                <p className='popCO'>{selectedRequest.Status}</p>

                {/* Display Payment Receipt URL if available */}
                {selectedRequest.paymentReceiptURL && (
                  <div>
                    <label 
                    style={{marginTop:'30px'}}
                    className='labeltext'>Payment Receipt</label>
                    <a href={selectedRequest.paymentReceiptURL} target="_blank" rel="noopener noreferrer" title="Open payment receipt">
                              <lord-icon
                              src="https://cdn.lordicon.com/gsjfryhc.json"
                              trigger="hover"
                              stroke="bold"
                              colors="primary:#0056B3,secondary:#0056B3"
                              style={{ width: "30px", height: "30px" }}
                              ></lord-icon> 
                    </a>
                  </div>
                )}
           
                <h3>Requested Items (URLs)</h3>
                <div className="desc-tab" style={{display:"flex",justifyContent:"center",alignItems:"center",justifySelf:"center"}}>
                <i className="fas fa-info-circle"></i> 
                    &nbsp; Kindly provide a quotation for all items in Maldivian Rufiyaa (MVR).
                </div>
                <div className="table-container">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>URL</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>QTY</th>
                        <th>Delivery Charge</th>
                        <th>Tax Amount</th>
                        <th>Total Price (MVR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(selectedRequest.product_reference_url || []).map((url, index) => (
                        <tr key={index}>
                          <td>
                            <a href={getUrlWithProtocol(url)} target="_blank" rel="noopener noreferrer" title="Open Product Link">
                            <lord-icon
                              src="https://cdn.lordicon.com/gsjfryhc.json"
                              trigger="hover"
                              stroke="bold"
                              colors="primary:#0056B3,secondary:#0056B3"
                              style={{ width: "30px", height: "30px" }}
                              ></lord-icon> 
                            </a>
                          </td>
                          <td>{(selectedRequest.Description || [])[index]}</td>

                          {/* Unit Price */}
                          <td>
                            <input
                              className='inputItems'
                              type="number"
                              value={(selectedRequest.Unit_price || [])[index] || ""}
                              onChange={(e) => {
                                const updatedPrices = [...(selectedRequest.Unit_price || [])];
                                updatedPrices[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  (updatedPrices[index] * (selectedRequest.QTY?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Unit_price: updatedPrices,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter price"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* QTY */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.QTY || [])[index] || ""}
                              onChange={(e) => {
                                const updatedQty = [...(selectedRequest.QTY || [])];
                                updatedQty[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  (updatedQty[index] * (selectedRequest.Unit_price?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  QTY: updatedQty,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter QTY"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Delivery Charge */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Delivery_charge || [])[index] || ""}
                              onChange={(e) => {
                                const updatedDeliveryCharge = [...(selectedRequest.Delivery_charge || [])];
                                updatedDeliveryCharge[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  ((selectedRequest.Unit_price?.[index] || 0) * (selectedRequest.QTY?.[index] || 0)) +
                                  updatedDeliveryCharge[index] +
                                  (selectedRequest.Tax_amount?.[index] || 0);

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Delivery_charge: updatedDeliveryCharge,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter Delivery Charge"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Tax Amount */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Tax_amount || [])[index] || ""}
                              onChange={(e) => {
                                const updatedTaxAmount = [...(selectedRequest.Tax_amount || [])];
                                updatedTaxAmount[index] = parseFloat(e.target.value) || 0;

                                const updatedTotalPrice = [...(selectedRequest.Total_price || [])];
                                updatedTotalPrice[index] =
                                  ((selectedRequest.Unit_price?.[index] || 0) * (selectedRequest.QTY?.[index] || 0)) +
                                  (selectedRequest.Delivery_charge?.[index] || 0) +
                                  updatedTaxAmount[index];

                                setSelectedRequest((prev) => ({
                                  ...prev,
                                  Tax_amount: updatedTaxAmount,
                                  Total_price: updatedTotalPrice,
                                }));
                              }}
                              placeholder="Enter Tax Amount"
                              disabled={
                                selectedRequest.Status !== "Agent Review Ongoing" &&
                                selectedRequest.Status !== "Pending"
                              } // Disable if status is neither "Agent Review Ongoing" nor "Pending"
                            />
                          </td>

                          {/* Total Price */}
                          <td>
                            <input
                              type="number"
                              value={(selectedRequest.Total_price || [])[index] || ""}
                              readOnly
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                </div>
                <div className='btnContainer'>
                {(selectedRequest.Status === "Agent Review Ongoing" || selectedRequest.Status === "Pending") && (
                  <button className='updateReq_btn' onClick={handleUpdateRequest}>Update Request</button>
                )}  
                {(selectedRequest.Status === "Payment Confirmed" || selectedRequest.Status === "Payment Verification Pending") && (
                  <button className='approveBtn' onClick={handleApprove}>Payment Approve</button>
                )}
                {(selectedRequest.Status === "Payment Verification Pending" || selectedRequest.Status === "Agent Review Ongoing" || selectedRequest.Status === "Pending") && (
                  <button className='reject_btn' onClick={handleReject}>Reject</button>)}
                {selectedRequest.Status === "Payment Request Completed" && 
                        (<button 
                          className='approveBtn'
                          onClick={handleOrderID}
                          >Submit</button>)}
                <button
                    
                    className='close_btn' onClick={handleClosePopup}>Close</button>
                </div>
              </>
            ) : (
              <>
              <button className="closeIcon" onClick={handleClosePopup}>
                  ×
              </button>  
                <h3>Add New Package</h3>

                {/* Package Type */}
                <label>Package Type</label>
                <select
                  value={newRequest.packageType || ''}
                  onChange={(e) => {
                    setNewRequest((prev) => ({ ...prev, packageType: e.target.value }));
                    if (e.target.value !== 'Assisted Shopping') {
                      setNewRequest((prev) => ({ ...prev, orderId: '' })); // Clear the Order ID if it's not Assisted Shopping
                    }
                  }}
                >
                  <option value="">Select Package Type</option>
                  <option value="Assisted Shopping">Assisted Shopping</option>
                  <option value="Shop Online">Shop Online</option>
                  <option value="Warehouse Drop off">Warehouse Drop off</option>
                </select>

                {/* Warehouse Location */}
                <label>Warehouse Location</label>
                <select
                  value={newRequest.warehouseLocation || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, warehouseLocation: e.target.value }))}
                >
                  <option value="">Select Warehouse Location</option>
                  <option value="Australia">Australia</option>
                  <option value="Canada">Canada</option>
                  <option value="China">China</option>
                  <option value="India">India</option>
                  <option value="Japan">Japan</option>
                  <option value="Malaysia">Malaysia</option>
                  <option value="Singapore">Singapore</option>
                  <option value="UAE">UAE</option>
                  <option value="UK">UK</option>
                  <option value="USA">USA</option>
                </select>

                {/* User ID */}
                <label>User ID</label>
                <Select
                    options={userSuggestions}
                    onChange={handleUserSelection}
                />

                {/* User Name */}
                <label>User Name</label>
                <input
                    type="text"
                    value={newRequest.requestName} // Auto-filled based on selection
                    readOnly
                />


                <div>
                    <label>Order ID</label>
                    <input
                      type="text"
                      value={newRequest.orderId || ''}
                      onChange={(e) => setNewRequest((prev) => ({ ...prev, orderId: e.target.value }))}
                      placeholder="Enter Order ID"
                    />
                  </div>

                {/* Tracking Number */}
                <label>Tracking Number</label>
                <input
                  type="text"
                  value={newRequest.trackingNumber || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, trackingNumber: e.target.value }))}
                  placeholder="Enter Tracking Number"
                />

                {/* Length */}
                <label>Length</label>
                <input
                  type="number"
                  value={newRequest.length || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, length: e.target.value }))}
                  placeholder="Enter Length"
                />

                {/* Width */}
                <label>Width</label>
                <input
                  type="number"
                  value={newRequest.width || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, width: e.target.value }))}
                  placeholder="Enter Width"
                />

                {/* Height */}
                <label>Height</label>
                <input
                  type="number"
                  value={newRequest.height || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, height: e.target.value }))}
                  placeholder="Enter Height"
                />

                {/* Volumetric Weight */}
                <label>Volumetric Weight</label>
                <input
                  type="number"
                  value={(newRequest.length * newRequest.width * newRequest.height) / 5000 || ""}
                  placeholder="Auto-calculated Volumetric Weight"
                  disabled
                  className="disabled-input" // Optional: Add a class to style the disabled field differently
                />


                {/* Actual Weight */}
                <label>Actual Weight</label>
                <input
                  type="number"
                  value={newRequest.weight || ''}
                  onChange={(e) => setNewRequest((prev) => ({ ...prev, weight: e.target.value }))}
                  placeholder="Enter Actual Weight"
                />
                <div className='addclose_container'>
                  <button className='updateReq_btn'onClick={handleAddNewRequest}>Add Package</button>
                  <button className='close_btn' onClick={togglePopup}>Close</button>
                </div>
              </>
              
            )}
          </div>
        </div>
      )}
    </div>


    {activeTab === 'shopOnline' && (
  <div className="admin-section">
    <h2>Shop Online Orders</h2>

    {/* Search Bar */}
    <div className="search-section">
      <input
        style={{marginBottom:"10px"}}
        type="text"
        placeholder="Search by Tracking No"
        value={searchTrackingNo}
        onChange={(e) => setSearchTrackingNo(e.target.value)}
        className="search-bar"
      />
    </div>

    {shopOnlineItems.length === 0 ? (
      <div className="loader-overlay">
        <div className="loader"></div>
      </div>
    ) : (
      <table className="admin-table">
        <thead>
          <tr>
           {/* <th>Request ID</th> */}
            <th>Item Name</th>
            <th>Description</th>
            <th>Platform</th>
            <th>Warehouse Location</th>
            <th>Tracking No</th>
            <th>Status</th>
            <th>Order Date</th>
            <th>Receiving Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredShopOnlineItems.length > 0 ? (
            filteredShopOnlineItems.map((item) => (
              <tr key={`${item.trackingNo}-${item.id}`}> 
                {/* <td>{item.trackingNo}</td>*/}
                <td>{item.itemName}</td>
                <td>{item.description}</td>
                <td>{item.platform}</td>
                <td>{item.warehouseLocation}</td>
                <td>{item.trackingNo}</td>
                <td>{item.status}</td>
                <td>
                  {item.orderDate
                    ? new Date(item.orderDate).toLocaleDateString()
                    : 'N/A'}
                </td>
                <td>
                  {item.receivingDate
                    ? new Date(item.receivingDate).toLocaleDateString()
                    : 'N/A'}
                </td>
                <td>
                  <button
                    onClick={() => {
                      setIsDialogOpen(true);
                      setSelectedItemId(item.id);
                    }}
                    disabled={item.status === 'Received'}
                    className="updateStatus-button"
                  >
                    Mark as Received
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="10" className="no-results">
                No orders found matching "{searchTrackingNo}".
              </td>
            </tr>
          )}
        </tbody>
      </table>
    )}
  </div>
)}


<Dialog
  open={isDialogOpen}
  onClose={handleCloseDialog}
  TransitionProps={{ timeout: 500 }}
  PaperProps={{
    style: {
      backgroundColor: "#fff",
      borderRadius: "35px",
      borderTop: "7px solid #0056B3",
      padding: "20px",
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    },
  }}
>
  <DialogTitle style={{ fontFamily: "Segoe UI", fontSize: "28px" }}>
    Confirm Action
  </DialogTitle>
  <DialogContent>
    Are you sure you want to mark this item as received?
  </DialogContent>
  <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Button
      style={{
        backgroundColor: "#0056B3",
        borderRadius: "25px",
        color: "white",
        textTransform: "none",
        fontFamily: "Segoe UI",
        transition: "background-color 0.3s ease",
      }}
      onClick={handleConfirmAction}
      color="primary"
    >
      Yes
    </Button>
    <Button
      style={{
        backgroundColor: "#DD3F3F",
        borderRadius: "25px",
        color: "white",
        textTransform: "none",
        fontFamily: "Segoe UI",
      }}
      onClick={handleCloseDialog}
      color="secondary"
    >
      No
    </Button>
  </DialogActions>
  </Dialog>
    
    <RejectModal
          isOpen={isRejectModalOpen}
          onClose={() => setIsRejectModalOpen(false)}
          onSubmit={handleRejectSubmit}
    />
  <ToastContainer/>
    </div>
  );
};

export default AdminPanel;
