import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./signin.css";
import { auth, db } from "../../firebase/Firebase";
import { signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Set up an authentication state observer
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check if the user is an admin
        const adminUsersRef = collection(db, "Admin_Users");
        const adminQuery = query(adminUsersRef, where("email", "==", user.email));
        const adminSnapshot = await getDocs(adminQuery);

        if (!adminSnapshot.empty) {
          navigate("/admin", { replace: true }); // Redirect to Admin Panel
        } else {
          navigate("/dashboard", { replace: true }); // Redirect to Dashboard
        }
      }
    });

    return () => unsubscribe(); // Cleanup the observer
  }, [navigate]);

  const handleSignIn = async (e) => {
    e.preventDefault(); // Prevent form refresh
    try {
      // Set persistence to browser local storage
      await setPersistence(auth, browserLocalPersistence);
  
      // Sign in the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the user is an admin
      const adminUsersRef = collection(db, "Admin_Users");
      const adminQuery = query(adminUsersRef, where("email", "==", user.email));
      const adminSnapshot = await getDocs(adminQuery);
  
      if (!adminSnapshot.empty) {
        console.log("Admin signed in successfully");
        navigate("/admin", { replace: true }); // Navigate to Admin Panel
      } else {
        console.log("User signed in successfully");
        navigate("/dashboard", { replace: true }); // Navigate to Dashboard
      }
    } catch (err) {
      console.error("Error signing in:", err);
      if (err.code === "auth/user-not-found") {
        toast.error("User not found. Please sign up.");
      } else if (err.code === "auth/wrong-password") {
        toast.error("Incorrect password.");
      } else {
        toast.error("Failed to sign in. Check your email and password again.");
      }
    }
  };

  return (
      <div className="signin-container">
        <div className="back-button-container" title="Back to Home">
          <img
              src={process.env.PUBLIC_URL + '/images/backBtn.png'}
              alt="back button"
              className="top-right-back-button"
              onClick={() => window.location.href = "https://fhl.ddb.myftpupload.com/"}
          />
        </div>
        <div className="signin-form">
          <img
              src="https://fhl.ddb.myftpupload.com/wp-content/uploads/2024/09/cropped-Fits-e-Parcel_Maldives-scaled-1.webp"
              alt="Fits e-Parcel Maldives Logo"
              className="logo-image"
          />
          <h2>Sign in</h2>
          <p>
            Don’t have an account?{" "}
            <span className="signup-link" onClick={() => navigate("/signup")}>Sign Up</span>
          </p>
          <form onSubmit={handleSignIn}>
            <div className="input-group">
              <label>Email address</label>
              <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
              />
            </div>
            <div className="forgot-password">
              <a href="/Reset-password">Forgot your password?</a>
            </div>
            <button type="submit" className="signin-btn">
              Sign in
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
  );
};

export default SignIn;
