import React from "react";
import ReactDOM from "react-dom";
import "./TermsModal.css";

const TermsModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="Onlinemodal-overlay">
      <div className="Onlinemodal-details">
        <button className="close-Onlinemodal" onClick={onClose}>
          &times;
        </button>
        <h2>Terms and Conditions</h2>
        <div style={{ textAlign: "left", maxHeight: "60vh", overflowY: "auto" }}>
          <p><strong>Effective Date:</strong> 01.01.2024</p>
          <p style={{marginTop:"5px", marginBottom:"18px"}}>
            Welcome to eParcel Maldives. By using our website<a href="https://www.eparcelmv.com"> www.eparcelmv.com </a>
            and services, you agree to comply with and be bound by the following terms 
            and conditions of use. Please read these terms carefully before using our services.
          </p>
          <h3>1. Business Description</h3>
          <p>eParcel Maldives provides shipping, assisted shopping, package handling, and 
            delivery services to the doorstep. Our services are available to customers primarily in the Maldives, 
            with assisted shopping options from Australia, Canada, China,India, Japan, Malaysia, Singapore, UAE, and USA</p>
          <h3>2. Payment Terms</h3>
          <ul>
            <li>&bull; Customers can pay via bank transfer for assisted shopping services. 
                Proof of payment must be submitted before the order is processed.
            </li>
            <li>&bull; Cash on delivery (COD) is available for logistics 
                and transportation services upon delivery. 
            </li>
            <li>&bull; Refunds and cancellations are processed on a case-by-case basis. 
                Requests must be submitted before the order is dispatched. 
                Refunds may be issued at the company’s discretion, depending on the service status and delivery progress.
            </li>
          </ul>
          <h3>3. User Responsibilities</h3>
            <ul>
                <li>&bull; eParcel Maldives is not liable for any damages, loss, or delay resulting 
                    from unforeseeable events such as customs holds, natural disasters, or third-party courier issues.</li>
                <li>&bull; In the event of assisted shopping, we are not liable to any counterfeit 
                    or item damages upon purchase (referring to the functionality)
                </li>
                <li>&bull; In the case of loss or damage to goods, eParcel Maldives’ liability is limited to the value of the services provided, 
                    and no further compensation will be issued.  Include upon investigation</li>
            </ul>
        <h3>5. Delivery Policies</h3>
            <ul>
                <li>&bull; 
                Delivery times are subject to the availability of services and the nature of the request. 
                Estimated delivery times will be provided upon order confirmation but may vary due to external factors, 
                including customs clearance and logistical challenges.
                </li>
                <li>&bull; Certain areas may have delivery restrictions, which will be communicated to 
                    the customer at the time of order.
                </li>
             </ul>
        
        <h3>6. Dispute Resolution</h3>
            <ul>
                <li>&bull; Any disputes arising from or related to the use of eParcel Maldives services shall first be resolved through mediation. If mediation fails, 
                    the parties agree to submit to the exclusive jurisdiction of the courts of the Maldives.</li>
                <li>&bull; Dispute resolution may vary between 1 to 6 weeks depending on the nature of the dispute</li>
            </ul>
        <h3>7. Termination of Services</h3>  
            <ul>
                <li>&bull; eParcel Maldives reserves the right to terminate or suspend services to any 
                    customer who violates these terms or engages in fraudulent or unlawful activities.
                </li>
                <li>&bull; Customers may terminate services by canceling their orders before shipment is initiated.
                </li>
            </ul>
        <h3>8. Governing Law</h3>  
            <p>These terms and conditions are governed by and construed in accordance with the laws of the Republic of Maldives. 
                Any disputes will be subject to the exclusive jurisdiction of Maldivian courts.</p>
        
        <h2>Privacy Policy</h2>
            <p>At eParcel Maldives, we value your privacy and are committed to protecting your personal information. 
                This Privacy Policy outlines how we collect, use, disclose, and protect your 
                information when you visit our website <a href="https://www.eparcelmv.com">www.eparcelmv.com</a> or use our services.</p>
          <h3>1. Data Collection</h3> 
            <p>We collect the following personal information when you register or place an order on our website:</p> 
                <ul>
                    <li>&bull; Full Name</li>
                    <li>&bull; National Identification Number</li>
                    <li>&bull; Email Address</li>
                    <li>&bull; Phone Number</li>
                    <li>&bull; Shipping Address</li>
                </ul>
            <p>We may also collect additional information required for order fulfillment and communication.</p>
          <h3>2. Data Usage</h3>   
            <p>The personal information collected by eParcel Maldives is used for:</p>
                <ul>
                    <li>&bull; Processing and fulfilling your orders.</li>
                    <li>&bull; Communicating with you regarding your orders, delivery status, 
                        and any customer service inquiries.
                    </li>
                    <li>&bull; Sending promotional offers, provided you have given consent to 
                        receive such communications.
                    </li>   
                </ul>
          <h3>3. Third-Party Sharing</h3>
            <p>eParcel Maldives may share your information with third parties necessary for
                 providing our services, such as:
            </p>
                <ul>
                    <li>&bull; Payment processors for handling transactions.</li>
                    <li>&bull; Courier services for fulfilling delivery.</li>
                    <li>&bull; Customs authorities where necessary for regulatory compliance.</li>   
                </ul>
            <p>We do not sell or share your personal data with unrelated 
                third parties for marketing purposes.
            </p>
          <h3>4. Security Measures</h3>
            <p>eParcel Maldives takes appropriate security measures to protect your personal data 
                from unauthorized access, misuse, alteration, or destruction. This includes encryption of 
                sensitive information and secure storage practices.
            </p>
            <p>Despite these measures, no method of transmission over the internet is entirely secure, 
                and we cannot guarantee the absolute security of your information.
            </p>
          <h3>5. Cookies/Tracking</h3>
            <p>Our website uses cookies to enhance user experience and to gather 
                information about visitors. Cookies are small files stored on your device that track usage patterns and preferences. You may adjust your browser settings to refuse cookies, but this may limit the functionality of certain website features.
            </p>
          <h3>6. User Rights</h3>
            <p>You have the following rights regarding your personal information:</p>
                <ul>
                    <li>&bull; The right to access your personal data and request correction or deletion.</li>
                    <li>&bull; The right to withdraw consent for marketing communications at any time.</li>
                    <li>&bull; The right to request that we cease processing your personal data for specific purposes, 
                        such as marketing.</li>   
                </ul>
            <p>To exercise any of these rights, you may contact us at [insert contact email or phone number].</p>
          <h3>7. Contact Information</h3>
            <p>If you have any questions or concerns about our 
                Privacy Policy or how your personal information is handled, please contact us at:</p>
                <ul>
                    <li>&bull; Email: hello@eparcelmv.com</li>
                    <li>&bull; Phone: +960 940 7050</li>  
                </ul>

        <h2>Assisted Shopping Terms and Conditions</h2>
            <p>Before placing an order, it is essential to carefully read and understand the following 
                terms and conditions to prevent any potential misunderstandings or issues.
            </p>
                <h3>Customer Requests</h3>
                    <ul>
                        <li>&bull; Customers are responsible for providing item/product links for purchase.</li>
                        <li>&bull; We recommend using links from reputable 
                            shopping platforms and checking seller or website ratings before submitting a request.</li> 
                        <li>&bull; The description of the items/products (including size, color, model) 
                            must be clearly specified when sending requests.</li> 
                        <li>&bull; eParcel Maldives will not search for or recommend items/products to customers.</li>
                    </ul>
                <h3>Purchase Invoice</h3>
                    <ul>
                        <li>&bull; Invoices for the requested items/products will be sent 
                            within 1-3 days from the date of request.
                        </li>
                        <li>&bull; Items that are out of stock or unavailable will not be included in the invoice.
                        </li> 
                        <li>&bull; The admin fee, calculated as a percentage of the total purchase amount, will be included in the invoice. 
                        </li>            
                    </ul>
                <h3>Purchase Payments & Refunds</h3>
                    <ul>
                        <li>&bull; Requests will only be processed once the customer has settled the invoiced amount.
                        </li>
                        <li>&bull; If an item/product becomes unavailable after payment but before purchase, the amount will be refunded to the customer.</li> 
                        <li>&bull; If the price of an item/product increases after the invoice is sent but before payment is made, the customer will need to settle the price difference for the request to proceed. 
                        </li>  
                        <li>&bull; Refunds will be processed within 5-7 working days from the date of the customer’s request.
                             <strong>This is also subjected to the third party website’s T&C</strong></li>          
                    </ul>
                <h3>Updates on Purchased Items</h3>
                    <ul>
                        <li>&bull; Updates on purchased items can be found in the Packages tab for each request.
                        </li>
                        <li>&bull; Once items are delivered to the Maldives Warehouse, the item status will be updated to "Received" along with the date of receipt.</li> 
                        <li>&bull; If a purchased item is canceled by the shopping platform for any reason, the value of the canceled item will be refunded to the customer. Please note that the admin fee and any related taxes (eg: vat, gst) are non-refundable. 
                            <strong>Please refer to (create a number system)</strong>
                        </li>  
                                  
                    </ul>

        </div>
        <div className="nextClose-container">
          <button className="closePageBtnTerms" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>,
    document.body // Appending modal to body
  );
};

export default TermsModal;
